/**
 * Общие КОНСТАНТЫ
 */

export const PASSWORD_MIN_LENGTH = 6;

/**
 * Выделены не часто встречающие имена куки
 * и вынесены в константы, чтобы не вводить каждый раз.
 */
export const COOKIE_UID_NAME = '_u_id';
export const COOKIE_TOKEN_NAME = '_aut_t';

export const COOKIE_EXPIRE_DAYS = 365;
/**
 * Урл страниц
 */
export const URL_USERS = '/users';
export const URL_LOGIN = '/login';
export const URL_SKETCHS = '/sketchs';
export const URL_TEMPLATES = '/templates';
export const URL_CHANGE_PASS = '/change-pass';

export const ADMIN_ID = 2048;

export const STYLE_LINK = { textDecoration: 'none', color: 'var(--link-color)' };

// Размеры изображений эскизов
// Выведены в JS переменные, так как JSX тег img не воспринимает CSS переменные
export const IMAGE_WIDTH = 350;
export const IMAGE_HEIGHT = 280;
export const IMAGE_WIDTH_MOBILE = 250;
export const IMAGE_HEIGHT_MOBILE = 180;

// Минимальный размер мобильно вида в пикселях
export const MOBILE_MAX_WIDTH = 1200;

// Для установки курсора в режим прокрутки при отправке формы
export const CURSOR_PROGRESS_STR = 'cursor: progress';
export const CURSOR_PROGRESS = { cursor: 'progress' };

// Количество эскизов в одной партии
export const SKETCHS_PAGE_SIZE = 6;

// Расстояние в пикселях от последнего эскиза до низа, в случае которой требуется подгрузка
export const SKETCHS_LAST_BOTTOM = 1200;

/**
 * Роль пользователей
 */
export const USER_ROLES: any = {
  '1': 'Незарегистрированный',
  '2': 'Зарегистрированный',
  '3': 'VIP',
  '4': 'SuperVip',
  '2048': 'Admin',
};

export const ALL_ROLES_ID = 5;

export const DOMAIN_FOR_COOKIE = window.location.origin
  .replace(/https?:\/\//, '')
  .replace(/:\d+\/?$/, '');

export const LOCAL_STORAGE_SIZE_ITEM_NAME = '_imageSize';
export const LOCAL_STORAGE_SEARCH_FILTER_NAME = '_searchFilter';
