import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as store from '../store';
import * as utils from '../utils';
import Logo from '../images/logo.svg';

const { body } = document;
const { CURSOR_PROGRESS_STR, CURSOR_PROGRESS, ADMIN_ID, URL_USERS } = utils.c;

const LOGIN = 'Логин';
const PASSWORD = 'Пароль';

/**
 * Страница входа
 * @returns
 */
function Login(): React.ReactElement {
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Прослушивает хранилище пользователя,
     * если уже пользователь есть и он является админом,
     * то редиректит к списку пользователей.
     */
    const clearStoreUserLintener = store.user.subscribe(() => {
      const { user, type } = store.user.getState();
      if (type === 'set') {
        if (user?.roleId === ADMIN_ID) {
          history.push(URL_USERS);
        }
      }
    });
    return () => {
      // Очищает прослушиватель хранилища пользователя
      clearStoreUserLintener();
    };
  }, []);

  return (
    <div className="container column center v-center full-height">
      <img
        style={{ marginBottom: 'var(--login-logo-margin-bottom)' }}
        src={Logo}
        alt="Логотип Петербургский Занавес"
      />
      <div className="container column start">
        <input
          placeholder={LOGIN}
          style={disabled ? CURSOR_PROGRESS : {}}
          disabled={disabled}
          value={email}
          onInput={(e: any) => {
            setEmail(e.target.value);
          }}
          id="email"
          className="form-item"
          type="email"
        />
        <input
          placeholder={PASSWORD}
          style={disabled ? CURSOR_PROGRESS : {}}
          disabled={disabled}
          value={password}
          onInput={(e: any) => {
            setPassword(e.target.value);
          }}
          id="password"
          type="password"
        />
      </div>
      <button
        style={disabled ? CURSOR_PROGRESS : {}}
        disabled={disabled}
        onClick={async () => {
          setDisabled(true);
          body.setAttribute('style', CURSOR_PROGRESS_STR);
          const result = await utils.r.login({
            email,
            password,
          });
          if (result?.status !== 201) {
            // eslint-disable-next-line no-alert
            alert(result.message);
            setDisabled(false);
            body.removeAttribute('style');
            return;
          }
          utils.h.setUidCookie(result.data?.userId || 0);
          utils.h.setTokenCookie(result.data?.accessToken || '');
          const user = await utils.r.getUser({
            id: result.data?.userId,
          });
          setDisabled(false);
          body.removeAttribute('style');
          if (user?.status !== 200) {
            // eslint-disable-next-line no-alert
            alert(user.message);
            return;
          }
          if (user.data.roleId !== utils.c.ADMIN_ID) {
            // eslint-disable-next-line no-alert
            alert('Запрещено');
            return;
          }
          store.user.dispatch({ type: 'set', user: user.data });
          history.push(utils.c.URL_USERS);
        }}
        className="active"
        type="button">
        Вход
      </button>
    </div>
  );
}

export default withRouter(Login);
