import React, { useEffect, useState } from 'react';
import { Route, useHistory, withRouter, Switch } from 'react-router-dom';
import './styles/App.scss';
import * as store from './store';
import * as utils from './utils';
import type { User } from './utils/Request';
import Login from './components/Login';
import Sketchs from './components/Sketchs';
import Users from './components/Users';
import Page404 from './components/404';
import Templates from './components/Templates';
import ChangePass from './components/ChangePass';

const { URL_LOGIN } = utils.c;

/**
 * Компонент уровня приложения
 * @returns
 */
function App(): React.ReactElement {
  const history = useHistory();

  const { body } = document;
  const [mobile, setMobile] = useState<boolean>(body.clientWidth < utils.c.MOBILE_MAX_WIDTH);
  const [me, setMe] = useState<User>();

  const uid = utils.h.getUidFomCookie();

  /**
   * Обработчик прослушивания размера окна
   */
  function scaleWindowHandler() {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('Non production log:');
      // eslint-disable-next-line no-console
      console.info('width', body.clientWidth);
      // eslint-disable-next-line no-console
      console.info('height', body.clientHeight);
    }
    /**
     * mobile это глобальный параметр, для определения мобильной ширины экрана,
     * если в компоненте нужно знать, что в мобильном случае отображается по разному.
     */
    if (body.clientWidth < utils.c.MOBILE_MAX_WIDTH && !mobile) {
      setMobile(true);
    } else if (body.clientWidth >= utils.c.MOBILE_MAX_WIDTH && mobile) {
      setMobile(false);
    }
  }

  useEffect(() => {
    // Прослушивает изменение размеров окна
    window.addEventListener('resize', scaleWindowHandler);

    const { pathname } = history.location;
    // Если нет куки с uid
    if (!uid && pathname !== URL_LOGIN && !pathname.match(new RegExp(utils.c.URL_CHANGE_PASS))) {
      history.push(URL_LOGIN);
      return;
    }

    /**
     * Получает данные пользователя
     */
    (async () => {
      if (uid && !me) {
        const serverUser = await utils.r.getUser({
          id: uid,
        });
        if (serverUser.status !== 200 && !pathname.match(new RegExp(utils.c.URL_CHANGE_PASS))) {
          window.location.pathname = URL_LOGIN;
          return;
        }
        // Если роль пользователя изменилась то редиректит его в форму входа
        if (serverUser.data.roleId !== utils.c.ADMIN_ID) {
          // eslint-disable-next-line no-alert
          alert('Запрещено');
          history.push(utils.c.URL_LOGIN);
          return;
        }
        setMe(serverUser?.data);
        store.user.dispatch({ type: 'set', user: serverUser.data });
      }
    })();

    return () => {
      // Чистит прослушивтель изменение размеров окна
      window.removeEventListener('resize', scaleWindowHandler);
    };
  }, [mobile]);
  return (
    <div className="App">
      <Switch>
        <Route path={utils.c.URL_LOGIN} component={Login} />
        <Route path={utils.c.URL_SKETCHS}>
          <Sketchs me={me} mobile={mobile} />
        </Route>
        <Route path={utils.c.URL_USERS}>
          <Users me={me} />
        </Route>
        <Route path={utils.c.URL_TEMPLATES}>
          <Templates mobile={mobile} />
        </Route>
        <Route path={`${utils.c.URL_CHANGE_PASS}/:id/`}>
          <ChangePass />
        </Route>
        <Route>
          <Page404 mobile={mobile} />
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(App);
