import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Cookies } from 'react-cookie';
import type { RouteComponentProps } from '../../types/index.d';
import * as utils from '../../utils';
import Logo from '../../images/logo.svg';

const { URL_USERS, URL_TEMPLATES, COOKIE_TOKEN_NAME } = utils.c;

const cookies = new Cookies();

interface ToolbarProps extends RouteComponentProps<any>, React.Props<any> {
  children?: React.ReactElement;
  big?: boolean;
}

/**
 * меню приложения
 * @param props
 * @returns
 */
function Toolbar(props: ToolbarProps): React.ReactElement {
  const { children, big } = props;
  const history = useHistory();
  const { pathname } = history.location;
  return (
    <div className={clsx('toolbar', big && 'big')}>
      <div className="column">
        <img className="small" src={Logo} alt="Логотип Петербургский Занавес" />
        <div className="toolbar-btns">
          <button
            onClick={() => {
              history.push(URL_TEMPLATES);
            }}
            type="button"
            className={clsx(pathname === URL_TEMPLATES && 'active')}>
            Эскизы
          </button>
          <button
            onClick={() => {
              history.push(URL_USERS);
            }}
            type="button"
            className={clsx(pathname === URL_USERS && 'active')}>
            Пользователи
          </button>
        </div>
        {children}
        <div className="container row v-center" style={{ margin: '2em' }}>
          <button
            type="button"
            onClick={async () => {
              // eslint-disable-next-line no-alert
              if (confirm('ВЫ действительно хотите выйти из текущей сесии?')) {
                cookies.remove(COOKIE_TOKEN_NAME);
                history.push('/login');
              }
            }}>
            Выйти
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Toolbar);
