/* eslint-disable no-unused-vars */
import { createStore } from 'redux';

interface SizeStore {
  type: 'set';
  size: number;
}

/**
 * Хранилище размера изображений
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const size = createStore((state: SizeStore | undefined = undefined, action: SizeStore) => {
  return action;
});

export default size;
