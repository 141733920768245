import { Cookies } from 'react-cookie';
import * as c from './config';
import r from './Request';

const cookies = new Cookies();

/**
 * Запись куки ид пользователя
 * @param uid
 */
// eslint-disable-next-line import/prefer-default-export
export function setUidCookie(uid: number): void {
  const date = new Date();
  date.setDate(date.getDate() + c.COOKIE_EXPIRE_DAYS);
  cookies.set(c.COOKIE_UID_NAME, uid, {
    sameSite: true,
    path: '/',
    secure: true,
    expires: date,
    domain: c.DOMAIN_FOR_COOKIE,
  });
}

/**
 * Запись куки токена пользователя
 * @param token
 */
export function setTokenCookie(token: string): void {
  const date = new Date();
  date.setDate(date.getDate() + c.COOKIE_EXPIRE_DAYS);
  cookies.set(c.COOKIE_TOKEN_NAME, token, {
    sameSite: true,
    path: '/',
    secure: true,
    expires: date,
    domain: c.DOMAIN_FOR_COOKIE,
  });
}

/**
 * Получение токена из куки
 * @returns
 */
export function getTokenFromCookie(): string {
  return cookies.get(c.COOKIE_TOKEN_NAME);
}

/**
 * Получение ид пользователя из куки
 * @returns
 */
export function getUidFomCookie(): number {
  const uid = parseInt(cookies.get(c.COOKIE_UID_NAME), 10);
  return isNaN(uid) ? 0 : uid;
}

/**
 * Берет ид из пути
 * @param pathname
 * @returns
 */
export function getIdFromPath(pathname: string): number {
  let id: any = pathname.match(/\d+$/);
  id = id ? id[0] : '0';
  return parseInt(id, 10);
}

/**
 * Скачивание файла
 */
export function saveFile(params: { imageHref: string; template: any }): () => Promise<void> {
  return async () => {
    const { imageHref, template } = params;
    let mime: any = imageHref.match(/\.[a-zA-Z0-9]{3,4}$/);
    mime = mime ? mime[0] : '';
    const result = await r.downloadFile({ url: imageHref });
    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${template.name}${mime}`);
    document.body.appendChild(link);
    link.click();
  };
}
