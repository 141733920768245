import React from 'react';
import * as store from '../../store';
import * as utils from '../../utils';

const { LOCAL_STORAGE_SIZE_ITEM_NAME } = utils.c;

interface ImageSizeProps {
  size: number;
}

function ImageSize(props: ImageSizeProps): React.ReactElement {
  const { size } = props;
  return (
    <div className="container row center">
      <label>Размер изображений</label>
      <select
        value={size}
        onChange={(e: any) => {
          const { value } = e.target;
          store.size.dispatch({ type: 'set', size: value });
          window.localStorage.setItem(LOCAL_STORAGE_SIZE_ITEM_NAME, value);
        }}>
        <option value={0.5}>Мелкие</option>
        <option value={1}>Средние</option>
        <option value={2}>Большие</option>
      </select>
    </div>
  );
}

export default ImageSize;
