/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as store from '../store';
import * as utils from '../utils';
import type { Sketch } from '../utils/Request';
import type * as Types from '../types/index.d';
import Toolbar from './blocks/Toolbar';
import Edit from '../images/edit.svg';
import ImageSize from './blocks/ImageSize';

const {
  IMAGE_HEIGHT,
  IMAGE_HEIGHT_MOBILE,
  IMAGE_WIDTH,
  IMAGE_WIDTH_MOBILE,
  SKETCHS_LAST_BOTTOM,
  USER_ROLES,
  SKETCHS_PAGE_SIZE,
  LOCAL_STORAGE_SIZE_ITEM_NAME,
  ADMIN_ID,
} = utils.c;

// Пагинация /////////////////////////
// Загрузка следующей партии эскизов
let load = true;
// Когда все эскизы загружены
let all = false;
// //////////////////////////// Пагинация

/**
 * Страница эскизов
 * @param props
 * @returns
 */
function Sketchs(props: Types.ComponentProps): React.ReactElement {
  const { mobile, me } = props;

  // Ссылка на последнего юнита списка для ПАГИНАЦИИ
  const lastElementRef = useRef<any>();
  // Страница для ПАГИНАЦИИ
  const [page, setPage] = useState<number>(1);

  // Принудительная перезагрузка
  const [r, setR] = useState<number>(0);

  const history = useHistory();

  const { pathname } = history.location;
  const id = utils.h.getIdFromPath(pathname);

  const [sketchsState, setSketchsState] = useState<Sketch[]>([]);
  const [nameState, setNameState] = useState<string>('');
  const [roleIdState, setRoleIdState] = useState<number>(0);
  const [phoneState, setPhoneState] = useState<string>('');
  const [emailState, setEmailState] = useState<string>('');
  // Коеффициент размера изображений
  const [imageSize, setImageSize] = useState<number>(
    parseFloat(window?.localStorage?.getItem(LOCAL_STORAGE_SIZE_ITEM_NAME) || '1')
  );
  // Пагинация /////////////////////////
  /**
   * Прослушивает прокрутку страницы, для подгрузки остальных эскизов
   */
  function windowScrollHandler() {
    if (lastElementRef?.current) {
      // Берет ссылку на последний элемент
      const { bottom } = lastElementRef.current.getBoundingClientRect();
      // Если расстояние от низа до последнего элемента меньше то, догружает следующую партию эскизов
      if (bottom < SKETCHS_LAST_BOTTOM && !load) {
        if (!all) {
          setPage(page + 1);
          load = true;
        }
      }
    }
  }
  // //////////////////////////// Пагинация

  useEffect(() => {
    // прослушивает изменение размера изображений
    const clearSetImageSizeHandler = store.size.subscribe(() => {
      const { size, type } = store.size.getState();
      if (type === 'set') {
        setImageSize(size);
      }
    });
    // Прослушиватель прокрутки
    window.addEventListener('scroll', windowScrollHandler);
    (async () => {
      // Если активна подгрузка части эскизов
      // Для ПАГИНАЦИИ проверяем load, и ставим его в false, а также ставим all=true если список окончен
      if (load && !all) {
        const sketchs = await utils.r.getSketchs({ id, page, pageSize: SKETCHS_PAGE_SIZE });
        if (sketchs.status !== 200) {
          // eslint-disable-next-line no-alert
          alert(sketchs.message);
          return;
        }
        // Ставим false чтобы избежать повторной подгрузки
        load = false;
        // Объединяем новую партию со всей коллекцией
        const oldSketchs = sketchsState;
        const newSketchs = oldSketchs.concat(sketchs.data);
        // Если это последняя партия то отключаем добавление страниц для прослушивателя прокрутки
        if (newSketchs.length === sketchs.pagination?.total) {
          all = true;
        }
        setSketchsState(newSketchs);
      }
      if (!nameState) {
        // Получает данные пользователя
        const user = await utils.r.getUser({ id });
        if (user?.status !== 200) {
          // eslint-disable-next-line no-alert
          alert(user?.message);
          return;
        }
        const { name, roleId, phone, email } = user.data;
        setNameState(name);
        setRoleIdState(roleId);
        setPhoneState(phone);
        setEmailState(email);
      }
    })();
    return () => {
      // Очищает прослушиватель прокрутки
      window.removeEventListener('scroll', windowScrollHandler);
      // Устанавливает глобальные значения в первоначальное состояние
      load = true;
      all = false;
      // Очищает прослушиватель store.size
      clearSetImageSizeHandler();
    };
    // Для работы ПАГИНАЦИИ, не забываем добавить page в массив
  }, [mobile, page, r]);

  const roles = Object.keys(USER_ROLES);

  return (
    <div className="container">
      <div className="column">
        <Toolbar>
          <div className="column">
            <ImageSize size={imageSize} />
            <div className="row center right">
              <img
                onClick={async () => {
                  if (roleIdState === ADMIN_ID) {
                    if (
                      // eslint-disable-next-line no-alert
                      !confirm(`Предоставить пользователю "${nameState}" права администратора?`)
                    ) {
                      return;
                    }
                  }
                  const updatedUser = await utils.r.updateUser({
                    id,
                    user: {
                      id,
                      name: nameState,
                      roleId: roleIdState,
                      phone: phoneState,
                      email: emailState,
                    },
                  });
                  if (updatedUser?.status !== 200) {
                    // eslint-disable-next-line no-alert
                    alert(updatedUser?.message);
                    return;
                  }
                  // TODO поведение после смены данных пользователя
                }}
                style={{ cursor: 'pointer' }}
                width={23}
                height={23}
                src={Edit}
                alt="Крандаш"
              />
              <span>Сохранить изменения</span>
            </div>
            <div className="row center right">
              <label>ID пользователя</label>
              <input type="text" disabled defaultValue={id} />
            </div>
            <div className="row center right">
              <label>Роль пользователя</label>
              <select
                disabled={me?.id === id}
                value={roleIdState}
                onChange={(e) => {
                  const { value } = e.target;
                  const roleInt = parseInt(value, 10);
                  setRoleIdState(roleInt);
                }}>
                {roles.map((role) => {
                  return (
                    <option key={role} value={role}>
                      {USER_ROLES[role]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="row center right">
              <label>Имя</label>
              <input
                type="text"
                value={nameState}
                onInput={(e: any) => {
                  setNameState(e.target.value);
                }}
              />
            </div>
            <div className="row center right">
              <label>Телефон</label>
              <input
                type="text"
                value={phoneState}
                onInput={(e: any) => {
                  setPhoneState(e.target.value);
                }}
              />
            </div>
            <div className="row center right">
              <label>Email</label>
              <input
                type="text"
                value={emailState}
                onInput={(e: any) => {
                  setEmailState(e.target.value);
                }}
              />
            </div>
          </div>
        </Toolbar>
      </div>
      <div className="block">
        <div className="wrap-container">
          {sketchsState.map((sketch, index, array) => {
            // Для ПАГИНАЦИИ ставим refElement для последнего юнита списка
            const refElement = !array[index + 1] ? lastElementRef : undefined;
            const s = imageSize || 1;
            const imageHref = `${process.env.REACT_APP_SERVER_URL}/static/users/${id}/${sketch.name}`;
            return (
              <div className="sketch-item" key={`sketch-${sketch.id}`}>
                <img
                  className="image"
                  width={mobile ? IMAGE_WIDTH_MOBILE * s : IMAGE_WIDTH * s}
                  height={mobile ? IMAGE_HEIGHT_MOBILE * s : IMAGE_HEIGHT * s}
                  alt={`Эскиз ${sketch.name}`}
                  src={imageHref}
                />
                <div ref={refElement} className="right">
                  <div className="container row">
                    <p
                      className="green"
                      onClick={utils.h.saveFile({ template: sketch, imageHref })}>
                      Скачать
                    </p>
                    <p
                      className="red"
                      style={{ marginLeft: '12px' }}
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (confirm(`Удалить изображение "${sketch.name}"?`)) {
                          (async () => {
                            const delRes = await utils.r.deleteSketch({
                              id: sketch.id,
                              userId: id,
                            });
                            if (delRes?.status !== 200) {
                              // eslint-disable-next-line no-alert
                              alert(delRes?.message);
                              return;
                            }
                            setPage(1);
                            setSketchsState([]);
                            setR(Math.random());
                          })();
                        }
                      }}>
                      Удалить
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Sketchs);
