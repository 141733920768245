import axios from 'axios';
import * as h from './helper';

/**
 * Тип данных Пользователь
 */
export type User = {
  email: string;
  name: string;
  phone: string;
  roleId: number;
  id: number;
};

/**
 * Тип данных Шаблон
 */
export type Template = {
  id: number;
  name: string;
  image: string;
  roleId: number;
  isOpen: boolean;
};

/**
 * Тип данных Эскиз
 */
export type Sketch = {
  id: number;
  name: string;
};

/**
 * Возвращаемый тип данных при авторизации
 */
export type Auth = {
  accessToken: string;
  refreshToken: string;
  userId: number;
  userRole: number;
};

/**
 * Параметры приватного метода send
 * обрабатывающего все запросы
 */
export interface SendParams {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  data?: any;
  params?: any;
  headers?: any;
  responseType?: 'blob';
}

/**
 * Параметры запроса авторизации
 */
export interface LoginParams {
  email: string;
  password: string;
}

/**
 * Результат запросов на сервер
 */
export interface Result<T> {
  status: number;
  message: string;
  pagination?: {
    page: number;
    pageSize: number;
    total: number;
  };
  data: T;
}

const server = process.env.REACT_APP_SERVER_URL;

/**
 * Класс реализующий все методы общения с сервером
 */
class Request {
  /**
   * Метод обрабатывающий все запросы на сервер
   * @param params
   * @returns
   */
  private async send(params: SendParams): Promise<any> {
    const { headers, responseType } = params;
    return new Promise((resolve) => {
      axios
        .request({
          ...params,
          headers: headers || {
            'Cotent-Type': 'application/json',
            Authorization: `Bearer ${h.getTokenFromCookie() || 'null'}`,
          },
          responseType: responseType || 'json',
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          if (error.response?.data) {
            resolve(error.response?.data);
          } else {
            resolve({
              status: 500,
              message: error.message || 'Сервер недоступен',
            });
          }
        });
    });
  }

  /**
   * Получение токена и ид пользователя
   * @param params
   * @returns
   */
  public async login(params: LoginParams): Promise<Result<Auth>> {
    return this.send({
      method: 'POST',
      url: `${server}/api/v1/auth`,
      data: params,
    });
  }

  /**
   * Получение пользователя
   * @param params
   * @returns
   */
  public async getUser(params: { id: number }): Promise<Result<User>> {
    const { id } = params;
    return this.send({
      method: 'GET',
      url: `${server}/api/v1/users/${id}`,
    });
  }

  /**
   * Изменение пользователя
   * @param params
   * @returns
   */
  public async updateUser(params: { id: number; user: User }): Promise<Result<User>> {
    const { id, user } = params;
    return this.send({
      method: 'PUT',
      data: user,
      url: `${server}/api/v1/users/${id}`,
    });
  }

  /**
   * Получение пользователей
   */
  public async getUsers(props: { roleId: number }): Promise<Result<User[]>> {
    const { roleId } = props;
    return this.send({
      method: 'GET',
      url: `${server}/api/v1/users`,
      params: { roleId },
    });
  }

  /**
   * Запросить токен для смены пароля
   */
  public async resetPassword(params: { email: string }): Promise<Result<Template[]>> {
    const { email } = params;
    return this.send({
      method: 'POST',
      url: `${server}/api/v1/requests/reset`,
      data: {
        email,
      },
    });
  }

  /**
   * Сменить пароль
   */
  public async changePassword(params: {
    id: number;
    newPassword: string;
    token: string;
  }): Promise<Result<any>> {
    const { id, newPassword, token } = params;
    return this.send({
      method: 'POST',
      url: `${server}/api/v1/users/${id}/reset-password`,
      data: {
        token,
        newPassword,
      },
    });
  }

  /**
   * Удаление пользователя
   */
  public async deleteUser(params: { id: number }): Promise<Result<User[]>> {
    const { id } = params;
    return this.send({
      method: 'DELETE',
      url: `${server}/api/v1/users/${id}`,
    });
  }

  /**
   * Добавить шаблон
   */
  public async postTemlpate(params: FormData): Promise<Result<User[]>> {
    return this.send({
      method: 'POST',
      url: `${server}/api/v1/templates`,
      data: params,
    });
  }

  /**
   * Скачать файл
   */
  public async downloadFile(params: { url: string }): Promise<BlobPart> {
    const { url } = params;
    return this.send({
      method: 'GET',
      url,
      responseType: 'blob',
    });
  }

  /**
   * Получение шаблонов
   */
  public async getTemplates(params: {
    page: number;
    pageSize: number;
    roleId: number;
  }): Promise<Result<Template[]>> {
    const { page, pageSize, roleId } = params;
    return this.send({
      method: 'GET',
      url: `${server}/api/v1/templates/${roleId}`,
      params: {
        page: page || 1,
        pageSize: pageSize || 20,
      },
    });
  }

  /**
   * Удаление шаблона
   */
  public async deleteTemplate(params: { id: number }): Promise<Result<Template[]>> {
    const { id } = params;
    return this.send({
      method: 'DELETE',
      url: `${server}/api/v1/templates/${id}`,
    });
  }

  /**
   * Изменение шаблона
   */
  public async updateTemplate(params: {
    id: number;
    name: string;
    roleId: number;
  }): Promise<Result<Template>> {
    const { id, name, roleId } = params;
    return this.send({
      method: 'PUT',
      url: `${server}/api/v1/templates/${id}`,
      data: {
        name,
        roleId,
      },
    });
  }

  /**
   * Получение эскизов
   */
  public async getSketchs(params: {
    id: number;
    page: number;
    pageSize: number;
  }): Promise<Result<Sketch[]>> {
    const { id, page, pageSize } = params;
    return this.send({
      method: 'GET',
      url: `${server}/api/v1/users/${id}/images`,
      params: {
        page: page || 1,
        pageSize: pageSize || 20,
      },
    });
  }

  /**
   * Удаление эскиза
   * // TODO апи нет
   */
  public async deleteSketch(params: { userId: number; id: number }): Promise<Result<Sketch[]>> {
    const { id, userId } = params;
    return this.send({
      method: 'DELETE',
      url: `${server}/api/v1/users/${userId}/images/${id}`,
    });
  }

  /**
   * Удаление изображения
   */
  public async deleteImage(params: { id: number }): Promise<Result<Sketch[]>> {
    const { id } = params;
    return this.send({
      method: 'GET',
      url: `${server}/api/v1/users/${id}/images`,
    });
  }
}

export default new Request();
