/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import parsed from 'querystring';
import { useHistory, withRouter } from 'react-router-dom';
import * as utils from '../utils';
import Close from '../images/close.svg';

const { PASSWORD_MIN_LENGTH, CURSOR_PROGRESS } = utils.c;

function ChangePassword(): React.ReactElement {
  const history = useHistory();
  const [password, setPassword] = useState<string>();
  const [passwordRepeat, setPasswordRepeat] = useState<string>();
  const [token, setToken] = useState<string>();
  const [dialogHidden, setDialogHidden] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  /**
   * Очистка диалга смены пароля
   */
  function clearChangePasswordDialog() {
    setPassword('');
    setPasswordRepeat('');
    setToken('');
  }

  let id: any = history.location.pathname.match(/\d+$/);
  id = id ? parseInt(id[0], 10) : null;

  useEffect(() => {
    const { search } = history.location;
    const qs = parsed.decode(search.replace('?', ''));
    const { t, d } = qs;
    if (typeof t === 'string' && d === 'yes') {
      setDialogHidden(false);
      setToken(t);
    }
  }, []);
  return (
    <div className="dialog" hidden={dialogHidden}>
      <div className="container column">
        <img
          style={{ margin: '2em 3em 0 auto', cursor: 'pointer' }}
          className="image"
          width={30}
          height={30}
          alt="Закрыть окно"
          src={Close}
          onClick={async () => {
            setDialogHidden(true);
          }}
        />
        <div className="container column full-size center v-center">
          <div className="add-template-container">
            <h3 style={{ marginBottom: '2em' }}>Смена пароля</h3>
            <div className="column center v-center">
              <label>Новый пароль</label>
              <input
                style={disabled ? CURSOR_PROGRESS : {}}
                disabled={disabled}
                type="password"
                value={password}
                onInput={(e: any) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="column center v-center">
              <label>Повтор нового пароля</label>
              <input
                style={disabled ? CURSOR_PROGRESS : {}}
                disabled={disabled}
                type="password"
                value={passwordRepeat}
                onInput={(e: any) => {
                  setPasswordRepeat(e.target.value);
                }}
              />
            </div>
            {/** Кнопка отправки запроса на смену пароля */}
            <div className="container center v-center">
              <button
                style={disabled ? CURSOR_PROGRESS : {}}
                disabled={disabled}
                onClick={async () => {
                  if (typeof password !== 'string' || password?.length < PASSWORD_MIN_LENGTH) {
                    // eslint-disable-next-line no-alert
                    alert(`Пароль должен быть не менее ${PASSWORD_MIN_LENGTH} символов`);
                    return;
                  }
                  if (password !== passwordRepeat) {
                    // eslint-disable-next-line no-alert
                    alert('Пароли не совпадают');
                    return;
                  }
                  if (!token) {
                    // eslint-disable-next-line no-alert
                    alert('Токен не передан');
                    return;
                  }
                  if (!id || isNaN(id)) {
                    // eslint-disable-next-line no-alert
                    alert('Ид пользователя не определен');
                    return;
                  }
                  // Меняет пароль
                  const result = await utils.r.changePassword({
                    id,
                    newPassword: password,
                    token,
                  });
                  setDisabled(false);
                  if (result?.status !== 200) {
                    // eslint-disable-next-line no-alert
                    alert(result?.message);
                    return;
                  }
                  clearChangePasswordDialog();
                  setDialogHidden(true);
                  // history.push(utils.c.URL_LOGIN);
                  window.location.assign('https://www.pzn.su');
                }}
                type="button"
                className="active">
                Отправить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ChangePassword);
