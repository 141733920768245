/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as utils from '../utils';
import * as store from '../store';
import type { User } from '../utils/Request';
import type * as Types from '../types/index.d';
import Toolbar from './blocks/Toolbar';
import Select from '../images/select.svg';
import Selected from '../images/selected.svg';

const { LOCAL_STORAGE_SEARCH_FILTER_NAME, ALL_ROLES_ID, USER_ROLES } = utils.c;

const roles = Object.keys(USER_ROLES);

// Сохранение пользователей для поиска
let usersGlobal: User[];

/**
 * Страница Пользователи
 * @param props
 * @returns
 */
function Users(props: Types.ComponentProps): React.ReactElement {
  const { me } = props;

  const searchInputRef = useRef<any>();

  const savedSearchFilter: any = window?.localStorage.getItem(LOCAL_STORAGE_SEARCH_FILTER_NAME);

  const [usersState, setUsersState] = useState<User[]>([]);
  const [searchFilter, setSearchFilter] = useState<'name' | 'email' | 'phone'>(
    savedSearchFilter || 'name'
  );
  const [roleIdState, setRoleIdState] = useState<number>(5);
  // Перерендер страницы
  const [r, setR] = useState<number>(0);

  /**
   * Обработчик удаления пользователя
   * @param _itsMe
   * @param user
   * @returns
   */
  function deleteUserHandler(_itsMe: boolean, user: User) {
    return () => {
      const attention = _itsMe
        ? '\n\nВнимание! Подтвердив действие, вы удалите пользователя текущей сессии!\n\n'
        : '';
      // eslint-disable-next-line no-alert
      if (confirm(`Удалить пользователя "${user.name}"? ${attention}`)) {
        (async () => {
          const delRes = await utils.r.deleteUser({ id: user.id });
          if (delRes?.status !== 200) {
            // eslint-disable-next-line no-alert
            alert(delRes?.message);
            return;
          }
          setR(Math.random());
        })();
      }
    };
  }

  /**
   * Обработчик смены роли шаблонов
   * @param roleInt
   */
  function changeRoleHandler(roleInt: number) {
    return () => {
      setUsersState([]);
      setRoleIdState(roleInt);
    };
  }

  useEffect(() => {
    (async () => {
      // Делаем запрос пользователей, только когда получили свои данные
      if ((me || store.user.getState()) && usersState.length === 0) {
        const users = await utils.r.getUsers({ roleId: roleIdState || ALL_ROLES_ID });
        setUsersState(users.data);
        usersGlobal = users.data;
        // TODO пагинация для пользователей не прописана, так как сервер возвращает нули
        // Пример реализации подгрузки по пагинации в файле components/Sketchs.tsx
      }
    })();
  }, [me, r, roleIdState]);
  return (
    <div className="container">
      <Toolbar>
        <div className="container column center">
          <label className="big">Искать по...</label>
          <select
            value={searchFilter}
            onChange={(e: any) => {
              const { value } = e.target;
              setSearchFilter(value);
              window.localStorage.setItem(LOCAL_STORAGE_SEARCH_FILTER_NAME, value);
              if (searchInputRef?.current) {
                searchInputRef.current.value = '';
              }
              if (usersGlobal !== usersState) {
                setUsersState(usersGlobal);
              }
            }}>
            <option value="name">имени</option>
            <option value="email">почте</option>
            <option value="phone">телефону</option>
          </select>
          <input
            ref={searchInputRef}
            onInput={(e: any) => {
              const { value } = e.target;
              const result: User[] = [];
              const sevenReg = /^\+?7/;
              switch (searchFilter) {
                case 'name':
                  for (let i = 0; usersGlobal[i]; i++) {
                    const user = usersGlobal[i];
                    const _user = user.name
                      .toLowerCase()
                      .match(new RegExp(`${value.toLowerCase()}`));
                    if (_user) {
                      result.push(user);
                    }
                  }
                  break;
                case 'email':
                  for (let i = 0; usersGlobal[i]; i++) {
                    const user = usersGlobal[i];
                    const _user = user.email
                      .toLowerCase()
                      .match(new RegExp(`${value.toLowerCase()}`));
                    if (_user) {
                      result.push(user);
                    }
                  }
                  break;
                case 'phone':
                  for (let i = 0; usersGlobal[i]; i++) {
                    const user = usersGlobal[i];
                    const _user = user.phone
                      .toString()
                      .replace(sevenReg, '8')
                      .match(`${value.replace('+', '').replace(sevenReg, '8')}`);
                    if (_user) {
                      result.push(user);
                    }
                  }
                  break;
                default:
              }
              setUsersState(result);
            }}
          />
        </div>
      </Toolbar>
      <div className="block">
        <div className="container row scroll">
          <div className="container row center">
            <img
              style={{ cursor: 'pointer' }}
              src={roleIdState === ALL_ROLES_ID ? Selected : Select}
              alt="Фильтр по всем ролям"
              onClick={changeRoleHandler(ALL_ROLES_ID)}
            />
            <label className="big">Все</label>
          </div>
          {roles.map((role) => {
            const roleInt = parseInt(role, 10);
            return (
              <div key={role} className="container row center">
                <img
                  style={{ marginLeft: '2em', cursor: 'pointer' }}
                  src={roleIdState === roleInt ? Selected : Select}
                  alt={`Фильтр по ${role}`}
                  onClick={changeRoleHandler(roleInt)}
                />
                <label className="big">{USER_ROLES[role]}</label>
              </div>
            );
          })}
        </div>
        <table>
          <tbody>
            {usersState.map((user) => {
              // проверяет что это пользователь сессии
              const _itsMe = me?.id === user.id || store.user.getState().user?.id === user.id;
              return (
                (user.roleId === roleIdState || roleIdState === 5) && (
                  <tr className="border-bottom" key={`user-${user.id}`}>
                    <td>
                      <Link to={`${utils.c.URL_SKETCHS}/${user.id}`} style={utils.c.STYLE_LINK}>
                        <label
                          style={
                            !_itsMe
                              ? { color: 'var(--black)', cursor: 'pointer' }
                              : { color: 'inherit', cursor: 'pointer' }
                          }
                          title={!_itsMe ? 'Данные пользователя' : 'Мои данные'}
                          className="big">
                          {user.name}
                        </label>
                      </Link>
                    </td>
                    <td className="right">
                      <p onClick={deleteUserHandler(_itsMe, user)} className="red">
                        Удалить
                      </p>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default withRouter(Users);
