/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import * as store from '../store';
import * as utils from '../utils';
import type { Template } from '../utils/Request';
import type * as Types from '../types/index.d';
import Toolbar from './blocks/Toolbar';
import Select from '../images/select.svg';
import Selected from '../images/selected.svg';
import Add from '../images/add.svg';
import Close from '../images/close.svg';
import ImageSize from './blocks/ImageSize';

const {
  IMAGE_HEIGHT_MOBILE,
  IMAGE_HEIGHT,
  IMAGE_WIDTH_MOBILE,
  IMAGE_WIDTH,
  SKETCHS_LAST_BOTTOM,
  SKETCHS_PAGE_SIZE,
  USER_ROLES,
  CURSOR_PROGRESS,
  CURSOR_PROGRESS_STR,
  ALL_ROLES_ID,
  ADMIN_ID,
  LOCAL_STORAGE_SIZE_ITEM_NAME,
} = utils.c;

const roles = Object.keys(USER_ROLES);

const { body } = document;

// Пагинация /////////////////////////
// Загрузка следующей партии эскизов
let load = true;
// Когда все эскизы загружены
let all = false;
// //////////////////////////// Пагинация

/**
 * Cтраница шаьлонов
 * @param props
 * @returns
 */
function Templates(props: Types.ComponentProps): React.ReactElement {
  const { mobile } = props;

  // Ссылка на последнего юнита списка для ПАГИНАЦИИ
  const lastElementRef = useRef<any>();
  // ссылка на скрытый инпут для добавления файла
  const fileRef = useRef<any>();
  // Для принудительного рендеринга
  const [r, setR] = useState<number>(0);
  // Для оключения активности полей во время загрузки шаблона
  const [disabled, setDisabled] = useState<boolean>(false);
  // Изображение
  const [image, setImage] = useState<any>(null);
  // Страница для ПАГИНАЦИИ
  const [page, setPage] = useState<number>(1);
  // переключение ролей для фильтра 0 - все
  const [roleIdState, setRoleIdState] = useState<number>(ALL_ROLES_ID);
  // переключение ролей для нового шаблона 0 - все
  const [roleIdNewState, setRoleIdNewState] = useState<any>(1);
  // Роль редактируемого шаблона
  const [roleIdEditState, setRoleIdEditState] = useState<any>(1);
  // Название нового шаблона
  const [name, setName] = useState<string>('');
  // Название редактируемого шаблона
  const [editName, setEditName] = useState<string>('');
  // ИД редактируемого шаблона
  const [editedId, setEditedId] = useState<number>();
  // Окно для добавления нового шаблона
  const [dialogHidden, setDialogHidden] = useState<boolean>(true);
  // Окно для редактирования шаблона
  const [dialogEditHidden, setDialogEditHidden] = useState<boolean>(true);
  // Коеффициент размера изображений
  const [imageSize, setImageSize] = useState<number>(
    parseFloat(window?.localStorage?.getItem(LOCAL_STORAGE_SIZE_ITEM_NAME) || '1')
  );

  // Пагинация /////////////////////////
  /**
   * Прослушивает прокрутку страницы, для подгрузки остальных эскизов
   */
  function windowScrollHandler() {
    if (lastElementRef?.current) {
      // Берет ссылку на последний элемент
      const { bottom } = lastElementRef.current.getBoundingClientRect();
      // Если расстояние от низа до последнего элемента меньше то, догружает следующую партию эскизов
      if (bottom < SKETCHS_LAST_BOTTOM && !load) {
        if (!all) {
          setPage(page + 1);
          load = true;
        }
      }
    }
  }
  // //////////////////////////// Пагинация

  const [templatesState, setTemplatesState] = useState<Template[]>([]);

  /**
   * Обработчик смены роли шаблонов
   * @param roleInt
   */
  function changeRoleHandler(roleInt: number) {
    return () => {
      setPage(1);
      setTemplatesState([]);
      setRoleIdState(roleInt);
    };
  }

  /**
   * Обработчик создания шаблона
   * @returns
   */
  async function addTemplateHandler() {
    if (image?.name === '') {
      // eslint-disable-next-line no-alert
      alert('Изображение не выбрано');
      return;
    }
    setDisabled(true);
    body.setAttribute('style', CURSOR_PROGRESS_STR);
    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', name);
    formData.append('roleId', roleIdNewState);
    const result = await utils.r.postTemlpate(formData);
    setDisabled(false);
    body.removeAttribute('style');
    if (result?.status !== 200) {
      // eslint-disable-next-line no-alert
      alert(result?.message);
      return;
    }
    setImage({ name: '' });
    setName('');
    setRoleIdNewState(1);
    setDialogHidden(true);
  }

  /**
   * Обработчик изменения шаблона
   * @returns
   */
  async function updateTemplateHandler() {
    if (!editedId) {
      // eslint-disable-next-line no-alert
      alert('Не выбран шаблон для удаления');
      return;
    }
    setDisabled(true);
    body.setAttribute('style', CURSOR_PROGRESS_STR);
    const result = await utils.r.updateTemplate({
      id: editedId,
      name: editName,
      roleId: roleIdEditState,
    });
    setDisabled(false);
    body.removeAttribute('style');
    if (result?.status !== 200) {
      // eslint-disable-next-line no-alert
      alert(result?.message);
      return;
    }
    setEditedId(0);
    setEditName('');
    setRoleIdEditState(0);
    setDialogEditHidden(true);
  }

  /**
   * Обработчик удаления шаблона
   * @param template
   * @returns
   */
  function deleteTemplateHandler(template: Template) {
    return () => {
      // eslint-disable-next-line no-alert
      if (confirm(`Удалить шаблон "${template.name}"?`)) {
        (async () => {
          const delRes = await utils.r.deleteTemplate({ id: template.id });
          if (delRes?.status !== 200) {
            // eslint-disable-next-line no-alert
            alert(delRes?.message);
            return;
          }
          setPage(1);
          setTemplatesState([]);
          setR(Math.random());
        })();
      }
    };
  }

  useEffect(() => {
    // прослушивает изменение размера изображений
    const clearSetImageSizeHandler = store.size.subscribe(() => {
      const { size, type } = store.size.getState();
      if (type === 'set') {
        setImageSize(size);
      }
    });
    // Прослушиватель прокрутки
    window.addEventListener('scroll', windowScrollHandler);
    (async () => {
      if (load && !all) {
        const templates = await utils.r.getTemplates({
          page,
          pageSize: SKETCHS_PAGE_SIZE,
          roleId: roleIdState,
        });
        if (templates?.status !== 200) {
          // eslint-disable-next-line no-alert
          alert(templates?.message);
          return;
        }
        // Ставим false чтобы избежать повторной подгрузки
        load = false;
        // Объединяем новую партию со всей коллекцией
        const oldTemplates = templatesState;
        const newTemplates = oldTemplates.concat(templates.data);
        // Если это последняя партия то отключаем добавление страниц для прослушивателя прокрутки
        if (newTemplates.length === templates.pagination?.total) {
          all = true;
        }
        setTemplatesState(newTemplates);
      }
    })();
    return () => {
      // Очищает прослушиватель прокрутки
      window.removeEventListener('scroll', windowScrollHandler);
      // Устанавливает глобальные значения в первоначальное состояние
      load = true;
      all = false;
      // Очищает прослушиватель store.size
      clearSetImageSizeHandler();
    };
    // Для работы ПАГИНАЦИИ, не забываем добавить page в массив
  }, [page, roleIdState, r]);
  return (
    <div className="container">
      <Toolbar>
        <ImageSize size={imageSize} />
      </Toolbar>
      <div className="block">
        <div className="container row scroll">
          <div className="container row center">
            <img
              style={{ cursor: 'pointer' }}
              src={roleIdState === ALL_ROLES_ID ? Selected : Select}
              alt="Фильтр по всем ролям"
              onClick={changeRoleHandler(ALL_ROLES_ID)}
            />
            <label className="big">Все</label>
          </div>
          {roles.map((role) => {
            const roleInt = parseInt(role, 10);
            return roleInt !== ADMIN_ID ? (
              <div key={role} className="container row center">
                <img
                  style={{ marginLeft: '2em', cursor: 'pointer' }}
                  src={roleIdState === roleInt ? Selected : Select}
                  alt={`Фильтр по ${role}`}
                  onClick={changeRoleHandler(roleInt)}
                />
                <label className="big">{USER_ROLES[role]}</label>
              </div>
            ) : (
              ''
            );
          })}
          <div className="container row center right">
            <img
              style={{ marginLeft: '2em', cursor: 'pointer' }}
              src={Add}
              alt="Кнопка Добавить шаблон"
              onClick={() => {
                setDialogHidden(false);
              }}
            />
            <label className="big">Добавить</label>
          </div>
        </div>
        {/** Блок вывода шаблонов */}
        <div className="wrap-container">
          {templatesState.map((template, index, array) => {
            // Для ПАГИНАЦИИ ставим refElement для последнего юнита списка
            const refElement = !array[index + 1] ? lastElementRef : undefined;
            const s = imageSize || 1;
            const imageHref = `${process.env.REACT_APP_SERVER_URL}/static/templates/${template.image}`;
            return (
              <div className="sketch-item" key={`sketch-${template.id}`}>
                <img
                  className="image"
                  width={mobile ? IMAGE_WIDTH_MOBILE * s : IMAGE_WIDTH * s}
                  height={mobile ? IMAGE_HEIGHT_MOBILE * s : IMAGE_HEIGHT * s}
                  alt={`Шаблон ${template.name}`}
                  src={imageHref}
                />
                <p>{template.name}</p>
                <div className="container row">
                  <p className="gray">{USER_ROLES[template.roleId]}</p>
                  <p
                    onClick={() => {
                      setEditedId(template.id);
                      setEditName(template.name);
                      setRoleIdEditState(template.roleId);
                      setDialogEditHidden(false);
                    }}
                    style={{ cursor: 'pointer', paddingLeft: '12px' }}
                    className="gray right">
                    Ред.
                  </p>
                </div>
                <div ref={refElement} className="right">
                  <div className="container row">
                    <p className="green" onClick={utils.h.saveFile({ template, imageHref })}>
                      Скачать
                    </p>
                    <p
                      className="red"
                      style={{ marginLeft: '12px' }}
                      onClick={deleteTemplateHandler(template)}>
                      Удалить
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/** Окно для добавления нового шаблона  */}
      <div className="dialog" hidden={dialogHidden}>
        <div className="container column">
          <img
            style={{ margin: '2em 3em 0 auto', cursor: 'pointer' }}
            className="image"
            width={30}
            height={30}
            alt="Закрыть окно"
            src={Close}
            onClick={() => {
              setDialogHidden(true);
            }}
          />
          <div className="container column full-size center v-center">
            <div className="add-template-container">
              <h3 style={{ marginBottom: '2em' }}>Добавить новый шаблон</h3>
              <div className="row center right">
                <label>Изображение</label>
                <input
                  disabled={disabled}
                  style={disabled ? CURSOR_PROGRESS : { cursor: 'pointer' }}
                  type="text"
                  value={image?.name}
                  onClick={() => {
                    if (fileRef?.current) {
                      fileRef.current.click();
                    }
                  }}
                />
              </div>
              <div className="row center right">
                <label>Название</label>
                <input
                  style={disabled ? CURSOR_PROGRESS : {}}
                  disabled={disabled}
                  type="text"
                  value={name}
                  onInput={(e: any) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="row center right">
                <label>Роль доступа</label>
                <select
                  style={disabled ? CURSOR_PROGRESS : {}}
                  disabled={disabled}
                  value={roleIdNewState}
                  onChange={(e) => {
                    setRoleIdNewState(parseInt(e.target.value, 10));
                  }}>
                  {roles.map((role) => {
                    return (
                      <option key={role} value={role}>
                        {USER_ROLES[role]}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/** Кнопка добавления нового шаблона */}
              <div style={{ marginTop: '2em' }} className="container center v-center">
                <button
                  style={disabled ? CURSOR_PROGRESS : {}}
                  disabled={disabled}
                  onClick={addTemplateHandler}
                  type="button"
                  className="active">
                  Создать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Скрытый инпут для добавления файла */}
      <input
        type="file"
        ref={fileRef}
        hidden
        onChange={(e: any) => {
          setImage(e.target.files[0]);
        }}
      />
      {/** Окно для редактирования шаблона  */}
      <div className="dialog" hidden={dialogEditHidden}>
        <div className="container column">
          {/** Изображенике для закрытия диалога редактирования */}
          <img
            style={{ margin: '2em 3em 0 auto', cursor: 'pointer' }}
            className="image"
            width={30}
            height={30}
            alt="Закрыть окно"
            src={Close}
            onClick={() => {
              setDialogEditHidden(true);
            }}
          />
          <div className="container column full-size center v-center">
            <div className="add-template-container">
              <h3 style={{ marginBottom: '2em' }}>Редактировать шаблон</h3>
              <div className="row center right">
                <label>Название</label>
                <input
                  style={disabled ? CURSOR_PROGRESS : {}}
                  disabled={disabled}
                  type="text"
                  value={editName}
                  onInput={(e: any) => {
                    setEditName(e.target.value);
                  }}
                />
              </div>
              <div className="row center right">
                <label>Роль доступа</label>
                <select
                  style={disabled ? CURSOR_PROGRESS : {}}
                  disabled={disabled}
                  value={roleIdEditState}
                  onChange={(e) => {
                    setRoleIdEditState(parseInt(e.target.value, 10));
                  }}>
                  {roles.map((role) => {
                    return (
                      <option key={role} value={role}>
                        {USER_ROLES[role]}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/** Кнопка изменения шаблона */}
              <div style={{ marginTop: '2em' }} className="container center v-center">
                <button
                  style={disabled ? CURSOR_PROGRESS : {}}
                  disabled={disabled}
                  onClick={updateTemplateHandler}
                  type="button"
                  className="active">
                  Изменить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Templates);
