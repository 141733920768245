/* eslint-disable no-unused-vars */
import { createStore } from 'redux';
import type { User } from '../utils/Request';

interface UserStore {
  type: 'set';
  user: User;
}

/**
 * Хранилище данных пользователя
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const user = createStore((state: UserStore | undefined = undefined, action: UserStore) => {
  return action;
});

export default user;
