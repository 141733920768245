import clsx from 'clsx';
import Toolbar from './blocks/Toolbar';

interface Page404Props {
  mobile: boolean;
}

/**
 * Страница 404
 * @returns
 */
function Page404(props: Page404Props): React.ReactElement {
  const { mobile } = props;
  return (
    <div className={clsx('container', mobile ? 'column' : 'row')}>
      <Toolbar />
      <div className="container column center v-center full-height">
        <h1>Страница не найдена(</h1>
      </div>
    </div>
  );
}

export default Page404;
